import * as React from 'react';
import Story from 'styleguide/components/Story/Story';

import img from 'assets/images/stories/make-something-vogue.jpg';

const DescriptionBlock = () => (
  <div>
    <p>Bringing an idea from concept to life can be tricky - but you&apos;ve got help.</p>
    <p>
      Learn about design, discuss product recommendations, review production options, and have your files
      checked before ordering.
    </p>
    <p>Focus on showcasing your originality.</p>
    You can leave the details to us.
  </div>
);

const StoryVogue = () => (
  <Story
    intro="Make Something"
    title="Vogue"
    description={<DescriptionBlock />}
    imageUrlMobile={img}
    imageUrlDesktop={img}
    direction="right"
    imageTitle="Make Something Vogue"
    imageAlt="Make something vogue"
  />
);

export default StoryVogue;
